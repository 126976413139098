
import { computed, defineComponent, PropType, reactive, ref, watch, watchEffect } from '@vue/composition-api'
import { cloneDeep } from 'lodash-es'
import { useNotify } from '@/store'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonDateInput from '@/components/common/CommonDateInput.vue'

import { dateDotNotationToDashWithForm, dateDashNotationToDotWithForm } from '@/utils/convertDate'

import { isRequired } from '@/utils/validation'
import { mapBasicEntityToIdWithForm } from '@/utils/mapBasicEntityToIdWithForm'
import { handleError } from '@/utils/handleError'
import { isOfType } from '@/utils/types/isOfType'

import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { NewsId, NewsInput, NewsOutput } from '@/api/types/news'
import { useCreateNews, useUpdateNews } from '@/api/news'

export default defineComponent({
  name: 'AddEditNewsDialog',
  components: {
    CommonAddEditDialog,
    CommonDateInput,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    entityName: {
      type: String,
      default: null,
    },
    entityId: {
      type: Number as PropType<NewsId>,
      default: null,
    },
    newsToEdit: {
      type: Object as PropType<NewsOutput>,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const { addNotification } = useNotify()

    const isEditMode = computed(() => Boolean(props.newsToEdit))

    const FORM_FIELDS: FormField[] = [
      {
        value: 'title',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('dashboard.news.form.title') as string)],
      },
      {
        value: 'content',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: true,
        rules: [(value: string) => isRequired(value, root.$t('dashboard.news.form.content') as string)],
      },
      {
        value: 'validUntil',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: false,
        rules: [],
      },
    ]

    const form = ref<NewsInput | NewsOutput>({} as NewsInput | NewsOutput)

    const { createNews, isLoading: isLoadingCreateNews } = useCreateNews()

    async function onAddNews(): Promise<void> {
      if (!isOfType<NewsOutput>(form.value, 'id')) {
        const updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, form.value)
        try {
          await createNews(updatedForm)

          addNotification({
            text: root.$t('dashboard.news.add.success') as string,
            type: 'success',
            timeout: 3000,
          })

          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const { updateNews, isLoading: isLoadingUpdateNews } = useUpdateNews()

    async function onEditNews(): Promise<void> {
      if (isOfType<NewsOutput>(form.value, 'id')) {
        let updatedForm = mapBasicEntityToIdWithForm<NewsOutput>(form.value)

        updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, updatedForm)
        try {
          await updateNews(updatedForm.id, updatedForm)

          addNotification({
            text: root.$t('dashboard.news.update.success') as string,
            type: 'success',
            timeout: 3000,
          })
          close()
        } catch (error: unknown) {
          handleError(error)
        }
      }
    }

    const isLoadingAddEditNews = computed(() => isLoadingCreateNews.value || isLoadingUpdateNews.value)

    function close(): void {
      emit('reload-news')

      emit('close')
    }

    watchEffect(() => props.entityId && (form.value[props.entityName] = props.entityId))

    watch(
      () => props.newsToEdit,
      () => {
        if (props.newsToEdit) {
          form.value = cloneDeep(props.newsToEdit)

          form.value = dateDashNotationToDotWithForm(FORM_FIELDS, form.value)
        }
      },
      { immediate: true }
    )

    return reactive({
      constants: {
        FORM_FIELDS_ENUM,
        DATA_TYPE,

        FORM_FIELDS,
      },
      state: {
        isEditMode,

        form,

        isLoadingAddEditNews,
      },
      listeners: {
        onAddNews,

        onEditNews,
      },
      functions: {
        formRules: {
          isRequired,
        },
      },
    })
  },
})
